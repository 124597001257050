import React, { useState } from "react";
import axios from "axios";

const VerifyClearance = () => {
  const [matricNumber, setMatricNumber] = useState("");
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const matricRegex = new RegExp(
    "^[0-9]{2}\\/[a-zA-Z]{3}[0-9]{2}\\/[0-9]{3,4}$",
    "i"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStudentData(null);
    setError(null);

    if (!matricRegex.test(matricNumber)) {
      setError("Invalid matriculation number format.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `https://hego3rip.com/api/abuad-alumni/clearance`,
        {
          params: { matricNumber },
          headers: {
            Authorization: "sk_live_eea599fe-3da9-4748-ee0e-729c31438887",
          },
        }
      );

      if (response.data.status && response.data.data.length > 0) {
        setStudentData(response.data.data[0]);
      } else {
        setError("No payment found");
      }
    } catch (error) {
      setError("Failed to fetch clearance data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg w-full max-w-md p-6">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
          Verify Student Clearance
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
          <div>
            <label
              htmlFor="matricNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Matriculation Number
            </label>
            <input
              type="text"
              id="matricNumber"
              value={matricNumber}
              onChange={(e) => setMatricNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="e.g., 18/law01/152"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            disabled={loading}
          >
            {loading ? "Verifying..." : "Submit"}
          </button>
        </form>

        {loading && (
          <p className="text-center text-indigo-500 mt-4">Loading...</p>
        )}

        {error && (
          <p className="text-center text-red-500 mt-4 font-bold text-3xl">
            {error}
          </p>
        )}

        {studentData && (
          <div className="mt-6 text-center">
            <img
              src={studentData.image}
              alt="Student"
              className="w-32 h-32 mx-auto rounded-full object-cover mb-4"
            />
            <p className="text-lg font-semibold">{`${studentData.firstName} ${studentData.middleName} ${studentData.lastName}`}</p>
            <p className="text-gray-800 text-2xl ml-3">
              Matric Number: {studentData.matricNumber}
            </p>
            {studentData.totalPaid === "50000" ? (
              <p className="text-green-600 text-6xl border-4 border-green-600">
                ALLOW ACCESS
              </p>
            ) : (
              <p className="text-red-600 text-6xl border-4 border-red-600">
                DENY ACCESS
              </p>
            )}
            <p className="mt-2 text-4xl">
              Total found:
              <span
                className={`ml-2 font-bold ${
                  studentData.totalPaid === "50000"
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {studentData.totalPaid}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyClearance;
