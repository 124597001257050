import React, { useState, useEffect } from 'react';
import { Table, Input, Typography, Spin, Modal } from 'antd';
// import 'antd/dist/antd.css';

const { Title } = Typography;
const { Search } = Input;

const Dashboard = () => {
  const [clearances, setClearances] = useState([]);
  const [totalClearances, setTotalClearances] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  // Function to fetch clearance data
  const fetchClearanceData = async (search = '', pageNumber = 1) => {
    setLoading(true);
    try {
      const endpoint = search
        ? `/clearance?${search.includes('@') ? `email=${search}` : `matricNumber=${search}`}`
        : `/clearance?page=${pageNumber}`;

      const response = await fetch(`https://hego3rip.com/api/abuad-alumni${endpoint}`, {
        headers: {
          Authorization: 'sk_live_eea599fe-3da9-4748-ee0e-729c31438887',
        },
      });
      
      if (!response.ok) throw new Error('Failed to fetch data');

      const data = await response.json();
      setClearances(data.data);
      setTotalClearances(data.meta.total);
    } catch (error) {
      console.error('Error fetching clearance data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch initial data on page load or page change
  useEffect(() => {
    fetchClearanceData('', page);
  }, [page]);

  // Handle search input
  const onSearch = (value) => {
    setSearchTerm(value);
    fetchClearanceData(value);
  };

  // Handle row click to open modal
  const onRowClick = (record) => {
    setSelectedStudent(record);
    setIsModalOpen(true);
  };

  // Close modal
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };

  // Define columns for the table
  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'firstName',
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    { title: 'Matric Number', dataIndex: 'matricNumber' },
    { title: 'Email', dataIndex: 'email' },
    { title: 'College', dataIndex: 'college' },
    { title: 'Department', dataIndex: 'department' },
    { title: 'Amount Paid', dataIndex: 'totalPaid', render: (text) => `₦${text || '0'}` },
    { title: 'Cleared', dataIndex: 'cleared', render: (text) => (text === 'true' ? 'Yes' : 'No') },
  ];

  return (
    <div className="container mx-auto p-4">
      <Title level={2}>Clearance Dashboard</Title>

      <div className="my-4">
        <Title level={4}>Total Clearances: {totalClearances}</Title>
      </div>

      <Search
        placeholder="Search by Email or Matric Number"
        enterButton="Search"
        size="large"
        onSearch={onSearch}
        className="my-4"
      />

      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={clearances}
          columns={columns}
          rowKey="id"
          pagination={{
            current: page,
            pageSize: 10,
            total: totalClearances,
            onChange: (page) => setPage(page),
          }}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
        />
      )}

      {/* Modal to display student details */}
      <Modal
        title="Student Clearance Details"
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedStudent && (
          <div>
            <img
              src={selectedStudent.image}
              alt="Student"
              style={{ width: '100%', height: 'auto', marginBottom: '16px', borderRadius: '8px' }}
            />
            <p><strong>Full Name:</strong> {`${selectedStudent.firstName} ${selectedStudent.middleName || ''} ${selectedStudent.lastName}`}</p>
            <p><strong>Matric Number:</strong> {selectedStudent.matricNumber}</p>
            <p><strong>Email:</strong> {selectedStudent.email}</p>
            <p><strong>Phone:</strong> {selectedStudent.phone}</p>
            <p><strong>Gender:</strong> {selectedStudent.gender}</p>
            <p><strong>Date of Birth:</strong> {selectedStudent.dateOfBirth}</p>
            <p><strong>Marital Status:</strong> {selectedStudent.maritalStatus}</p>
            <p><strong>Religion:</strong> {selectedStudent.religion}</p>
            <p><strong>Nationality:</strong> {selectedStudent.nationality}</p>
            <p><strong>State of Origin:</strong> {selectedStudent.stateOfOrigin}</p>
            <p><strong>Local Govt:</strong> {selectedStudent.localGovt}</p>
            <p><strong>Home Location:</strong> {selectedStudent.homeLocation}</p>
            <p><strong>College:</strong> {selectedStudent.college}</p>
            <p><strong>Department:</strong> {selectedStudent.department}</p>
            <p><strong>Amount Paid:</strong> ₦{selectedStudent.totalPaid || '0'}</p>
            <p><strong>Cleared:</strong> {selectedStudent.cleared === 'true' ? 'Yes' : 'No'}</p>
            <p><strong>Created At:</strong> {selectedStudent.createdAt}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;