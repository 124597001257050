import React, { useEffect, useState } from "react";
import axios from "axios";

const ClearanceTable = () => {
  const [clearances, setClearances] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch clearance data from API
  useEffect(() => {
    const fetchClearances = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          "https://hego3rip.com/api/abuad-alumni/clearance?totalPaid=50000&perPage=2000",
          {
            headers: {
              Authorization: "sk_live_eea599fe-3da9-4748-ee0e-729c31438887",
            },
          }
        );
        if (response.data.status) {
          setClearances(response.data.data);
        } else {
          setError("Failed to retrieve clearances");
        }
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchClearances();
  }, []);

  // Filter clearances by matric number
  const filteredClearances = clearances.filter((clearance) =>
    clearance.matricNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <p className="text-center text-lg">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-500">{error}</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold text-center mb-4">
        Clearance Records
      </h1>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Matric Number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 p-2 rounded-lg w-full"
        />
      </div>

      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Matric Number</th>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Amount Paid</th>
            <th className="py-2 px-4 border-b">Cleared</th>
          </tr>
        </thead>
        <tbody>
          {filteredClearances.map((clearance) => (
            <tr key={clearance.id}>
              <td className="py-2 px-4 border-b">{clearance.matricNumber}</td>
              <td className="py-2 px-4 border-b">
                {clearance.firstName} {clearance.middleName}{" "}
                {clearance.lastName}
              </td>
              <td className="py-2 px-4 border-b">{clearance.amountPaid}</td>
              <td className="py-2 px-4 border-b">
                {clearance.cleared === "true" ? "Yes" : "No"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {filteredClearances.length === 0 && (
        <p className="text-center text-lg mt-4">No records found</p>
      )}
    </div>
  );
};

export default ClearanceTable;
