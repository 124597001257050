import { useEffect } from "react";


const NoticeOfElection = () => {
  useEffect(() => {
    window.location.href = "https://alumni.abuad.edu.ng/dev/election.html";
  }, []);

  return null;
};

export default NoticeOfElection;
